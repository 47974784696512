import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Box, Text, Card, Flex, Button } from 'theme-ui'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

const styles = {
  imageWrapper: {
    borderRadius: `default`,
    overflow: `hidden`,
    position: `relative`
  },
  button: {
    display: [`none`, `block`],
    position: `absolute`,
    bottom: 4,
    right: 4
  },
  grid: {
    flexWrap: [`wrap`, `wrap`, `nowrap`],
    ' > div + div': {
      ml: [0, 0, 5]
    }
  },
  column: {
    flex: `auto`,
    flexBasis: [`full`, `full`, `1/2`]
  }
}

export default props => {
  const data = useStaticQuery(aboutQuery)

  const { photo } = data
  const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <>
      <Seo title='Hakkımda' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Hakkımda'
            subheader='“Başarı, ilk adımı atabilme ve sürdürülebilir kılma sanatıdır.”'
          />
          <Divider />
          <Box sx={styles.imageWrapper}>
            <Img fluid={fluid} />
            <Button sx={styles.button}>İletişim</Button>
          </Box>
          <Divider />
          <Flex sx={styles.grid}>
            <Box sx={styles.column}>
              <Section title='Hikayem'>
                <Text variant='p'>
                 Yaşama gözümü açtığımdan beri, hep bir şeyleri geliştirmek için çalışıyorum. Bunun ne olduğu hiç önemli değil.
                 Okduğum kitabın bir bölümünü, yaşadığım evin atmosferini, yürüdüğüm yolun zeminini.
                </Text>
                <Text variant='p'>
                  Liseden mezun olduktan sonra, kısa bir üniversite hayatım oldu ve ilk yılın sonuna doğru başka şeylere kafa yormamdan dolayı eğitim hayatımı askıya aldım. 
                2020 pandemi krizinin, fiziksel olarak yaptığım tüm aksiyonları, zorunlu bir şekilde dijitale taşıdığı için boşta kalan vaktimi tekrar lisans eğitimine yönlendirdim.
                </Text>
                <Text variant='p'>
                  Türkiye ve Dünya pazarına ürün-hizmetler geliştiren ve bunların ticareti ile gelir elde eden bir kaç projenin ortaklığında bulunmaktayım.
                  İçinde bulunduğum projelerin ve şirketlerin daha sürdürülebilir bir biçime evrilmesi ve ekosistemlerini geliştirmeleri için çeşitli aksiyonlar alıyorum.
                </Text>
                <Text variant='p'>
                  Bu sayfayı neden aktif ettiğimi açıklamak isterim; Uzun zamandır kendi fikirlerimi, teorilerimi ve okuduğum-yaşadığım olaylardan çeşitli sonuçlar çıkarıyor ve bunları kendime notlar adı altında kaleme alıyordum.
                  Bazı yazıları arkadaşlarımla ve network ağımda bulunan kişilerle parça parça paylaşıyordum. Sık sık "bu yazıları artık paylaşıma açmalısın." gibi sözleri duyduktan sonra blogumu tekrar aktif ettim.
                </Text>
                <Text variant='p'>
                  Nelerle ilgileniyorum ?
                  <ul>
                    <li>Sosyal Bilimler</li>
                    <li>Matematik</li>
                    <li>Python ile Yapay Zeka </li>
                    <li>Ekonomi Bilimi </li>
                    <li>Uzay Bilimleri ve Biyoteknoloji </li>
                  </ul>
                </Text>
                <Text variant='p'>
                  Profesyonel olarak ilgilendiğim iş kolları dışında, hobi olarak yukarıdaki alanlar ile ilgili okumalar, araştırmalar ve çeşitli çalışmalar yapıyorum.
                 
                </Text>

              
              </Section>
            </Box>
            <Box sx={styles.column}>
              <Section title='İş Geliştirme'>
                <Card variant='paper'>
                Şirketlerin dijital dünyada gerek pazar paylarını, gerek ürün niteliğini, gereksede ortaya çıkardığı ekosistemi geliştirme ve büyütmeye yönelik çalışmalar yapmaktayım.
                Şirket içi iletişimden, satış sonrası desteğine kadar bir çok alanda iyileştirmeler yapmaya odaklanıyorum.
                </Card>
              </Section>
              <Divider />
              <Section title='Dijital Pazarlama'>
                <Card variant='paper'>
                  Yüksek teknoloji hizmetleri sunan SaaS projeleri öncelikli olmak üzere içinde bulunduğum şirketlerin dijital pazarlama ve markalaşma operasyonlarına destek veriyorum.
                </Card>
              </Section>
              <Divider />
              <Section title='Kişisel Markalaşma'>
                <Card variant='paper'>
                 Dijital yaşama gözlerini açan (profil oluşturan) tüm insanların, bir kişisel marka sahibi olduğunu savunuyorum. Bu konuda çeşitli çalışmalar gerçekleştiriyorum.
                </Card>
              </Section>
            </Box>
          </Flex>
        </Main>
      </Stack>
    </>
  )
}

const aboutQuery = graphql`
  query AboutQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
